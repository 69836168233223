.wrapper {
  width: 400px;
  position: fixed;
  z-index: 15;
  bottom: 10px;
  right: -400px;
}

.active {
  transition: 0.5s all;
  right: 10px;
}
